<template
  src="@/../../../templates/brand/components/templates/shop/GiftCredit.html"
>
</template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState, mapGetters } from 'vuex'
// import { Address } from '@/models/ProfileModel'
import CreditProfiles from '@/components/CreditProfiles.vue'
import acl from '@/../../../templates/site/UI/app/acl-rules'
import accounting from 'accounting'
import validator from 'validator'
// import purify from 'dompurify'
// const _blacklist = '\\[?\$\_\{\}\[\\]\%\=\<\>\~\`\`\\\/"' // eslint-disable-line no-useless-escape

// logic
export default {
  name: 'ECerts',
  mounted() {
    this.owner = this.user.email

    this.order.billing = this.locations.find(
      l => l._id === this.user.profile.billing
    )
    this.order.shipping = this.locations.find(
      l => l._id === this.user.profile.shipping
    )

    if (
      !acl['ecert.payment.methods'] ||
      acl['ecert.payment.methods']().includes('cc')
    ) {
      if (this.appconfig.siteConfig && this.appconfig.siteConfig.checkout) {
        this.moneris_hpp_url = this.appconfig.siteConfig.checkout.gatewayHost
        this.moneris_tokenization_id = this.appconfig.siteConfig.checkout.profileId
        if (
          this.appconfig.siteConfig &&
          this.appconfig.siteConfig.ecertsExpireAfter
        ) {
          this.$http
            .get('/giftcards/expirydate/' + Date.now(), this.order)
            .then(res => {
              if (res.data.success) this.expiryDate = res.data.data
            })
        }
      } else {
        let unwatch = this.$watch('appconfig.siteConfig', () => {
          this.moneris_hpp_url = this.appconfig.siteConfig.checkout.gatewayHost
          this.moneris_tokenization_id = this.appconfig.siteConfig.checkout.profileId
          if (
            this.appconfig.siteConfig &&
            this.appconfig.siteConfig.ecertsExpireAfter
          ) {
            this.$http
              .get('/giftcards/expirydate/' + Date.now(), this.order)
              .then(res => {
                if (res.data.success) this.expiryDate = res.data.data
              })
          }
          unwatch()
        })
      }
    } else if (
      acl['ecert.payment.methods']().includes('po') &&
      acl['ecert.payment.methods']().length === 1
    ) {
      this.paymentType = 'po'
    }

    this.$watch('amount', () => {
      if (this.paymentType === 'po' && this.ccOnly) this.paymentType = 'cc'
      if (this.poOnly) this.paymentType = 'po'
    })
  },
  components: { CreditProfiles },
  data() {
    return {
      listView: true,
      purchaseView: false,
      receiptView: false,
      transferView: false,
      paymentType: 'cc',
      poNumber: '',
      name: '',
      amount: 0,
      purchase_for: 'other',
      owner: '',
      description: '',
      validRecipient: false,
      orderRecipient: null,
      moneris_hpp_url: '',
      moneris_tokenization_id: '',
      ticket: {},
      purchase: {},
      order: { billing: '', shipping: '', price: { subtotal: 0 } },
      selectedCC: null,
      certificates: [],
      changeBillingLocation: false,
      expiryDate: null
    }
  },
  computed: {
    ...mapState({
      user: ({ App }) => App.user,
      certs: ({ ECert }) => ECert.certs,
      locations: ({ Location }) => Location.locations
    }),
    ...mapGetters({
      appconfig: 'const'
    }),
    certsExpire() {
      return (
        !!this.appconfig.siteConfig &&
        this.appconfig.siteConfig.ecertsExpireAfter
      )
    },
    ecerts() {
      if (!this.certs) return []
      return _.reverse(
        _.sortBy([c => new Date(c.created).getTime()], this.certs)
      )
    },
    runningBalance() {
      return _.reduce((sum, i) => (sum += i.balance), 0, this.ecerts)
    },
    invalid() {
      const d = new Date()
      if (this.amount < 1) return true
      if (
        this.purchase_for === 'other' &&
        (!this.validRecipient || this.orderRecipient.email === this.user.email)
      )
        return true
      if (
        this.paymentType === 'cc' &&
        (!this.selectedCC ||
          d >
            new Date(
              `20${this.selectedCC.ResDataExpDate.slice(
                0,
                2
              )}-${this.selectedCC.ResDataExpDate.slice(2)}-15`
            ))
      )
        return true
      return this.paymentType === 'credit' && this.amount > this.balance
    },
    billingAddress() {
      if (this.order && this.order.billing) return this.order.billing
      else {
        const _loc = this.locations.find(
          l => l._id === this.user.profile.billing
        )
        return {
          contact: _loc.contact,
          email: _loc.email,
          phone: _loc.phone,
          address: _loc.address
        }
      }
    },
    balance() {
      return _.reduce((sum, i) => sum + i.balance, 0, this.ecerts)
    },
    ccOnly() {
      if (this.poOnly) return false
      if (!this.appconfig.siteConfig.paymentMethods.po) return true
      const _lvl =
        this.user.profile.extradata.paymentLevel || this.user.role.level
      if (acl['payment.methods'](_lvl) === ['cc']) return true
      return (
        ~['delinquent'].indexOf(this.user.status) ||
        (this.appconfig.siteConfig.POMinimum &&
          this.amount < this.appconfig.siteConfig.POMinimum)
      )
    },
    poOnly() {
      const certMethods = acl['ecert.payment.methods']()
      return certMethods.includes('po') && certMethods.length === 1
    },
    canPurchase() {
      if (
        (this.poOnly &&
          acl['payment.methods'](this.user.role.level).includes('po')) ||
        (this.ccOnly &&
          acl['payment.methods'](this.user.role.level).includes('cc'))
      ) {
        return true
      }
      return false
    },
    showAdd() {
      return !this.selectedCC
    }
  },
  methods: {
    ...mapActions([
      'getCert',
      'getCerts',
      'getLocations',
      'getOrder',
      'cancelOrder',
      'completeOrder',
      'closeOrder'
    ]),
    debounce(evt) {
      console.info(' :: use evt', evt)
      _.debounce(this.testEmail, 500)
    },
    changeOwner() {
      if (this.purchase_for === 'other') this.owner = ''
      else this.owner = this.user.email
    },
    testEmail(evt) {
      console.info(' ::: testemail', evt)
      if (!(~evt.target.value.indexOf('@') && ~evt.target.value.indexOf('.')))
        return
      this.validRecipient = false
      if (validator.normalizeEmail(evt.target.value)) {
        this.$http
          .get(`public/users/validemail/${this.owner}`)
          .then(res => {
            console.info(' ::: test email', res)
            // const u = res.data.user
            if (
              res.data.success &&
              !res.data.error &&
              res.data.user.active &&
              res.data.user.status === 'active'
            ) {
              this.orderRecipient = res.data.user
              this.validRecipient = true
            } else {
              if (res.data.error) this.invalidMessage = res.data.error
              if (
                res.data.user &&
                !(res.data.user.active || res.data.status === 'active')
              )
                this.invalidMessage = 'Recipient is not active.'
            }
          })
          .catch(err => {
            console.error('got an error', err)
          })
      }
    },
    changePaymentType(evt) {
      console.info('changed payment type', evt)
      this.paymentType = evt.target.value
      // this.unverifiedOrder = true;
      // this.startOrderMethod(this.paymentType);
    },
    setBillingAddress(evt) {
      const _val = evt.target.value
      const _loc = this.locations.find(l => l._id === _val)
      this.order.billing = {
        contact: _loc.contact,
        email: _loc.email,
        phone: _loc.phone,
        address: _loc.address
      }
    },
    submit() {
      this.order.type = 'credit'
      this.order.purchaser = this.user.email
      this.order.items = [
        {
          name: this.name,
          description: this.description,
          owner: this.owner,
          amount: this.amount
        }
      ]
      if (
        this.appconfig.siteConfig &&
        this.appconfig.siteConfig.ecertsExpireAfter
      )
        this.order.items[0].expiry = this.expiryDate
      this.order.price.total = Number(accounting.toFixed(this.amount, 2))
      this.order.price.subtotal = this.order.price.total
      this.order.price.shipping = 0
      this.order.price.taxes = {}
      this.order.billing = this.order.billing
        ? this.order.billing
        : this.billingAddress
      this.order.paymentMethod = this.paymentType
      if (this.paymentType.toLowerCase() === 'po')
        this.order.referenceNum = this.poNumber
      if (this.paymentType.toLowerCase() === 'cc')
        this.order.paymentProfile = this.selectedCC
      if (this.paymentType.toLowerCase() === 'credit')
        this.order.price.credit = this.amount
      // create order
      this.$http
        .post('/orders?userdate=' + Date.now(), this.order)
        .then(res => {
          if (res.data.order) {
            if (res.data.error) {
              console.warn(
                'The server returned errors validating the order.',
                res.data.error
              )
              this.$vex.dialog.alert({
                message: `The transaction failed. Message: ${res.data.error}`
              })
            } else if (res.data.success) {
              this.order = res.data.order // {price: {subtotal: 0}};
              // this.order.billing = this.user.profile.billing
              // this.order.shipping = this.user.profile.shipping
              this.orderreceipt = res.data.order
              this.certificates = res.data.credit
              this.goToView('receiptView')
              this.getCerts()
              // }
            }
          } else {
            /* no order was created! */
            console.error('no order was created by server...')
          }
        })
        .catch(err => {
          // error validating order...
          console.error('there was an error validating the order...')
          return err
        })
    },
    completePurchase(id, data) {
      this.$http.post(`/giftcards/${id}/purchase/complete`, data).then(res => {
        console.info('completed credit purchase', res)

        this.order = res.data.data.order
        this.billingform = _.assign({}, this.billingAddress)
        this.order.billing = this.user.profile.billing
        this.order.shipping = this.user.profile.shipping
        this.orderreceipt = res.data.data.order
        this.certificates = res.data.data.credit
        this.getCerts()
      })
    },
    goToView(view) {
      if (this.receiptView) {
        this.clearForm()
      }
      this.transferView = false
      this.listView = false
      this.purchaseView = false
      this.receiptView = false
      this[view] = true
      if (view === 'transferView') this.paymentType = 'credit'
      else {
        this.paymentType =
          this.paymentType === 'credit' ? 'cc' : this.paymentType
      }
    },
    clearForm() {
      this.paymentType = 'cc'
      this.name = ''
      this.amount = 0
      this.purchase_for = 'other'
      this.owner = this.user.email
      this.description = ''
      this.validRecipient = false
      this.ticket = {}
      this.purchase = {}
      this.order = { price: { subtotal: 0 } }
      this.order.billing = this.billingAddress
      this.order.shipping = this.user.profile.shipping
    },
    currencyLocale(val) {
      return accounting.formatMoney(
        val,
        this.appconfig.locale[this.$i18n.locale].currency
      )
    },
    creditProfileCallback(type, data) {
      const _exec = {
        start: (this.selectedCC = data),
        select: (this.selectedCC = data)
      }
      return _exec[type]
    }
  },
  beforeDestroy() {
    this.closeOrder()
  },
  created() {
    this.getCerts()
    if (!this.locations) this.getLocations()
  }
}
</script>

<style
  src="@/../../../templates/brand/components/styles/shop/GiftCredit.css"
></style>
